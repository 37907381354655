@mixin animation($prop) {
    -moz-animation: $prop;
    -webkit-animation: $prop;
}

@mixin transition($prop) {
    -moz-transition: $prop;
    -o-transition: $prop;
    -webkit-transition: $prop;
    transition: $prop;
}

@mixin filter($prop) {
    -webkit-filter: $prop;
    filter: $prop;
}

@mixin transform($prop) {
    -moz-transform: $prop;
    -ms-transform: $prop;
    -webkit-transform: $prop;
    transform: $prop;
}

@mixin animation-fill-mode($prop) {
    -moz-animation-fill-mode: $prop;
    -webkit-animation-fill-mode: $prop;
    animation-fill-mode: $prop;
}

@mixin no-select() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}