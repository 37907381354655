.ky_crd_addordr{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border-color:$trnsp;
    background-color:$gry-fa;
    .ant-card-body {
        padding: 0;
    }
}

.ky_crd_addordr_bdy{
    display: inline;
    float: right;
    @media(max-width:968px){
        position: absolute;
        right: 0px;
        top: -55px;
    }
    @media(max-width:572px){
        position: unset;
        right: 0px;
        top: 0px;
        float: left;
    }
}

.ky_crd_opt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    margin-top: 0.5rem;
    border: 1px solid $blck-06;
    background: $trnsp;
    @media (max-width: 500px) {
        > .ant-card-body{
            padding: 16px;
        }
    }
}

.ky_crd_opt_bdy {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ky_crd_addordr_ttl{
    cursor: default;
    font-size: 25px;
    margin-bottom: 0.5em;
}

.kyu-rw {
    padding: 0;
}

.kyu-rw-ttl{
    justify-content: center;
    margin-top: 20px;
}

.ky_btn_cstmr_srch{
    width: calc(100% - 0.5rem);
    margin-left: 0.5rem;
}

.ky_btn_ordr_add{
    float: inline-end;
}

.ky_btn_ordr_retry{
    background-color: $blck !important ;
}

.ky_add_retry_cntnr{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ky_btn_cntnr_spc_btwn{
    display: flex;
    justify-content: space-between;
}

.kyu-rw-npt{
    width: 100%;
    .ky_order_no{
        width: 100%;
        input{
            margin-right: 0.5rem;
            @media (max-width: 500px) {
                width: 100% !important;
                margin-bottom: 0.5rem;
            }
        }
        button{
            width: 120px;
        }

        .edit-btn {
            margin-right: 0.5rem;
        }
    }
    .ky_order_no_add_width {
        input{
            width: calc(100% - 120px - 0.5rem);
        }
    }

    .ky_order_no_edit_width {
        input{
            width: calc(100% - 240px - 1rem);
        }
    }
    .ant-select-selector{
        min-height: 30px;
        height: 30px !important;
        input{
            width: 100% !important;
        }
        .ant-select-selection-placeholder{
            padding: 0;
        }
    }
    .ky_grp_no,
    .ky_tbl_no{
        width: 100%;
        .ant-select,
        input{
            width: calc(100% - 240px - 1rem) !important;
            margin-right: 0.5rem;
            @media (max-width: 500px) {
                width: 100% !important;
                margin-bottom: 0.5rem;
            }
        }
        .ant-select{
            input{
                width: 100% !important;
            }
        }
        button{
            width: 120px;
            &.add-group,
            &.add-table{
                margin-right: 0.5rem;
            }
        }
    }
}

.kyu-rw-swtch{
    width: 54%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @media (max-width: 400px) {
        width: 100%;
    }
}

.kyu-npt-cntnr{
    width: 100%;
}

.kyu-btn {
    margin: 0 1em 0;
}

.ky_link {
    padding: 0;
    color:$rng-1;
    height: initial;
    span{
        text-decoration: underline;
    }
}

.ky_link:hover{
    color:$rng-1;
}

.kyu-align-cntr {
    display: flex;
    justify-content: center;
    align-items: center;
}

.kyu-txt-lgn {
    text-align: center;
}

.kyu-fnt-lrg {
    font-size: 20px;
}

.ky_err_txt {
    color:$rd-3 !important;
    margin-top: 0.5rem;
}

.ky_cstmr_txt{
    margin-top: 0.5rem;
}

.kyu-radio-btn {
    display: block;
    height: 30px;
    line-height: 30px;
}

.kyu-radio-btn-dsc{
    font-size: 12px;
    padding-left: 23px;
}

.ky_add_btn_cntnr{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}

.ky_btn_addordr{
    background: linear-gradient($rng-2 20%, $rng-3 80%) !important;
    border-radius: 6px;
    border-color:$rng-1;
}

.ky_btn_nwordr{
    border-radius: 6px !important;
}

.ky_nwordr_txt{
    font-weight: 600;
}

.ky_nwordr_drwr_txt{
    color: $gry-7;
    margin-bottom: 0;
}

.ky_drwr{
    .ant-drawer-content-wrapper{
        max-width: 500px;
        width: 100% !important;
        .ant-drawer-body{
            padding: 2rem;
        }
        .ant-drawer-close{
            margin: .5rem .5rem 0 0;
            padding: 0;
            width: 20px;
            min-height: 20px;
            border-radius: 40px;
            background: $rd-4;
            svg{
                width: 10px;
                fill: $wht;
            }
            &:hover, &:focus{
              background: $rd-3;
            }
        }
    }
}

.kyu-npt-dtls{
    width: 100%;
    > * {
        width: 33%;
        display: inline-block;
        margin: 0;

        &:nth-child(1){
            text-align: left;
            color: $gry-6;
        }
        &:nth-child(2){
            font-weight: 500;
            color: $blck;
            font-size: 1rem;
            text-align: center;
        }
        &:nth-child(3){
            text-align: right;
        }
    }
}

.ky_srch_cstmr{
    font-weight: 600;
}

.ky_srch_ldng{
     color: $rng-7;
}

.ky_ordr_no_hnt{
    font-size: 12px;
    padding-top: 0.5rem;
    color: $rd-3;
}