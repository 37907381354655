@keyframes count-anim {
  from {transform: translateX(10px);}
  to {transform: translateX(-20px);}
}

.side-panel {
  background-color: $gry-fa;
  max-width: 250px !important;
  flex: 0 0 250px !important;
  width: 100% !important;
  .ky_main_menu{
    border-right: 1px solid $gry-e3;
    box-shadow: 2px 0 16px 0 $blck-1;
    height: calc(100% - 60px);

    .ky_sdr_header_sync{
      display: block;
      text-align:left;
      margin-bottom:10px;
      height: 70px;
    }
  }
  .ky_menu_divider {
    margin: 0 1.5rem !important;
    background-color: $gry-e3 !important;
    position: relative;
    z-index: 1;
  }
  .ky_menu_item {
    padding:0 10px !important;
    height: 50px;

    .ky_menu_count{
      text-align: right;

      &.animated {
        color: $rng-7;
        animation: count-anim 1s linear 0s infinite alternate;
      }
    }

    .ky_menu_icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 47px;
    }
  }
  .ant-layout-sider-trigger {
    background: $gry-52;
    color: $wht;
    width: 250px !important;
    border-right: 1px solid $gry-e3;
    box-shadow: 2px 0 16px 0 $blck-1;
    &:hover{
      background: $gry-5b;
    }
  }
  &.ant-layout-sider-collapsed{
    width: 80px !important;
    flex: 0 0 80px !important;
    .ant-layout-sider-trigger {
      width: 80px !important;
    }
    .ky_logo {
      &.ky_logo_img {
        max-width: 50px;
      }
    }
  }
}

.ky_logo_sec {
  height: 80px;
  background: $gry-fa;
}

.ky_logo {
  &.ky_logo_img_header {
    @include transition(0.3s);
    max-width: 100px;
    top: -14px;
    position: absolute;
    left: 10px;
  }
  &.ky_logo_sdr {
    display: block;
    text-align: center;
    height: 60px;
    background: $gry-52;
  }
}

.Kyu-logged-user {
  margin-right: 3px;
  .ky_btn_usr {
    height: 42px;
    margin-top: 3px;
  }
}

.ky_hdr {
  background: $gry-52;
  height: 60px;
  padding: 0 1.5rem;
}

.Kyu-layout {
  min-height: 100vh;
}

.ky_prof_info{
  border: none;
  text-align: center;
  width: 250px;
  .ant-card-body{
    padding: 1.5rem 0.5rem;
    span{
      svg{
        width: 80px;
        height: 80px;
        padding: 20px;
        border-radius: 80px;
        border: 2px solid $rng-1;
        margin-bottom: 1rem;
      }
    }
  }
}

.Kyu-tablet-menu-toggle-button {
  background-color: inherit;
  border-color: $wht;
}

.Kyu-tablet-menu-toggle-icon {
  color: $wht;
}

.Kyu-content {
  padding: 0.5rem;
}

.ky_btn_usr {
  margin-left: auto;
  float: right;
  padding: 0;
}

.ky_menu_item:hover {
  color: $blck-1;
}

.ky_mn_btn {
  background: $rng-linear;
  border-radius: 6px;
}

.ky_vtr {
  display: block;
  background-color: $wht;
  margin-top: 10px;
  margin-right: 3px;
  svg{
    color: $gry-a !important;
    width: 16px;
    height: 16px;
  }
}

.ky_sml_drawer{
  .ant-drawer-content-wrapper{
    max-width: 300px;
    width: 100% !important;
    .ant-drawer-body{
      padding: 0;
      .ky_logo{
        width: 80px;
        display: block;
        margin: 3rem auto 1.5rem;
      }
    }
  }
}

.Kyu-tablet-menu-toggler{
  .Kyu-tablet-menu-toggle-button{
    border: none;
    margin: 1rem 0;
    svg{
      fill: $gry-6;
      width: 20px;
      height: 20px;
    }
  }
}