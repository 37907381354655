.ky_grd_nm{
    margin-bottom: 0.5rem;
}

.ky_swtch{
    float: right;
    margin-right: 0;
}

.ky_stng_txt{
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
}

.ky_crd_vn_stng{ 
    display: block;
    margin: 1rem auto;
    width: 90%;
    background: $trnsp;
    .ant-card-body{
        padding: 0;
        > .ant-row{
            padding: 0.5rem 0;
        }
    }

    @media (max-width:769px){
        margin-left: 20px;
        width: 95%;
    }
    @media (max-width){
        width:70%
    }

    &.dyn_hgt{
        height: fit-content;
    }
}

.ky_vn{
    cursor: default;
}

.ky_vn_btn{
    float: right;
    background: $rng-linear !important;
    border-radius: 6px;
    border-color:$rng-1;
    color: $blck;
}

.ky_srv_prd_chp{
    border-radius: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gry-46;
    margin-top: 10px;
    cursor: pointer;
    .title{
        font-weight: 500;
        padding: 0px 20px;
    }

    .time{
        font-size: 13px;
        padding: 0px 20px;
        font-style: italic;
    }
}

.ky_srv_mdl_tip{
    border-color: $yllw-8;
    background-color: $yllw-7;
    color: $gry-46;
    font-size: 12px;
    font-style: italic;
    .ant-card-body{
        padding: 10px;
    }
    .icon{
        color:$yllw-6;
        * {
            font-size: 16px;
        }
    }

    .icon_hldr{
        text-align: center;
    }
    b{
        font-weight: 500;
    }
}

.ky_srv_footer{
    height: auto;
    padding-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    button {
        margin-left: 5px;
    }
}

.ky_vn_hnt {
    float: left;
    padding: 2px 10px;
    background-color: $rd-0;
    border-radius: 3px;
    color: $wht;
    margin-bottom: 10px;
}

@keyframes scale {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}

.ky_gt_fcs {
    animation: scale 1s ease-in-out infinite alternate;
    -webkit-animation: scale 1s ease-in-out infinite alternate;
}