$trnsp: transparent;
$wht: #FFFFFF;
$wht-f238: #F2F3F8;

$blck:#000000;
$blck-2:rgba(0,0,0,0.2);
$blck-1:rgba(0,0,0,0.1);
$blck-06:rgba(0,0,0,0.06);
$blck-68: #686868;

$gry-fa:#FAFAFA;
$gry-fe:#FEFEFE;
$gry-f7:#F7F7F7;
$gry-f1:#F1F1F1;
$gry-e:#EEEEEE;
$gry-eb:#EBEBEB;
$gry-e8:#E8E8E8;
$gry-e3:#E3E3E3;
$gry-d:#DDDDDD;
$gry-d1:#D1D1D1;
$gry-ce:#CECECE;
$gry-c:#cccccc;
$gry-co:#C0C0C0;	
$gry-a9:#A9A9A9;
$gry-a:#aaaaaa;
$gry-9:#999999;
$gry-7:#777777;
$gry-8:#888888;
$gry-6:#666666;
$gry-5:#555555;
$gry-4:#444444;
$gry-37:#373737;
$gry-3:#333333;
$gry-2:#222222;
$gry-1:#111111;
$gry-ed:#EDEFF4;
$gry-9c:#9CA0AA;
$gry-5b: #5b5b5b;
$gry-52 : #525660;
$gry-46: #464646;

$rng-1:#E76F51;
$rng-2:#f29a5f;
$rng-3:#e87252;
$rng-4:#F4A261;
$rng-5:#FCF4F2;
$rng-6: #e76f51;
$rng-7:#ff6640;
$rng-8:#ffb170;
$rng-9:#f1e1d9;
$rng-10:#930000;
$rng-11:#ead3c7;

$grn-1: #23BD23;
$grn-2:#4AEC9B;
$grn-3:#00B259;
$grn-4:#58f8a8;
$grn-5:#03c564;
$grn-6:#338066;
$grn-7:#e6ffe6;

$pnk-1: #FEEEE2;
$pnk-2: #f8d7da;
$pnk-3:#f5c6cb;

$rd-0:#721c24;

$yllw-1: #F6C627;
$yllw-2:#ffc400;
$yllw-3:#FCDD85;
$yllw-4:#eeb90b;
$yllw-5:#ffdb7a;
$yllw-6:#FF7A00;
$yllw-7:#ffffb3;
$yllw-8:#ffff00;

$bl-1: #45A4F3;
$bl-2:#7DD9F6;
$bl-3:#008EBB;
$bl-4:#9ee6fc;
$bl-5:#04aadd;

$rd-1:#FFD8D8;
$rd-2:#ff9b9b;
$rd-3:#e02020;
$rd-4:#FF5F57;
$rd-5:#ffadad;
$rd-6:#fa1212;

$rng-linear:linear-gradient(0deg, $rng-1 50%, $rng-4 100%);
$rng-linear-2:linear-gradient(0deg, $rng-7 50%, $rng-8 100%);
$rng-linear-3:linear-gradient(0deg, $rng-11 50%, $rng-9 100%);
$rng-linear-4:linear-gradient(0deg, $gry-52 50%, $gry-7 100%);

$ylw-linear:linear-gradient(0deg, $yllw-4 50%, $yllw-5 100%);
$ylw-linear-2:linear-gradient(0deg, $yllw-2 50%, $yllw-3 100%);

$bl-linear:linear-gradient(0deg, $bl-3 50%, $bl-2 100%);
$bl-linear-2:linear-gradient(0deg, $bl-5 50%, $bl-4 100%);

$rd-linear:linear-gradient(0deg, $rd-3 50%, $rd-2 100%);
$rd-linear-2:linear-gradient(0deg, $rd-6 50%, $rd-5 100%);

$grn-linear:linear-gradient(0deg, $grn-3 50%, $grn-2 100%);
$grn-linear-2:linear-gradient(0deg, $grn-5 50%, $grn-4 100%);

$gry-linear:linear-gradient(0deg, $gry-e8 50%, $gry-d1 100%);
$gry-linear-2:linear-gradient(0deg, $gry-ce 50%, $gry-eb 100%);

$drk-linear:linear-gradient(0deg, $gry-37 50%, $gry-9 100%);
$drk-linear-2:linear-gradient(0deg, $gry-2 50%, $gry-7 100%);