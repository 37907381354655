* {
  font-size: 16px;
  @media (max-width: 991px) {
    font-size: 14px;
  }
}
.ky_btn {
  background-color: $trnsp;
  border-radius: 6px;
  border: none !important;
  outline: 0;
  line-height: 20px;
  box-shadow: 0 2px 4px $blck-2;
  height: 30px;
  color: $wht;
  @include transition(0s);
  padding: 4px 25px;
  &.ky_btn_wd {
    width: 100%;
  }
  &.ky_btn_lng {
    padding: 0 1rem;
    @media (max-width: 767px) {
      padding: 0 1rem;
    }
  }
  &.ky_btn_lng_notify {
    padding: 0 2rem;
    @media (max-width: 767px) {
      padding: 0 1rem;
    }
  }
  &.ky_btn_lrg {
    padding: 0 2rem;
    height: 45px;
    @media (max-width: 767px) {
      padding: 0 1rem;
    }
  }
  &.ky_btn_rng {
    background: $rng-linear;
    &:hover,
    &:focus {
      background: $rng-linear-2;
      color: $wht;
    }
  }
  &.ky_btn_grn {
    background: $grn-linear;
    &:hover,
    &:focus {
      background: $grn-linear-2;
      color: $wht;
    }
  }
  &.ky_btn_yllw {
    background: $ylw-linear;
    color: $gry-6;
    &:hover,
    &:focus {
      background: $ylw-linear-2;
      color: $gry-6 !important;
    }
  }
  &.ky_btn_bl {
    background: $bl-linear;
    &:hover,
    &:focus {
      background: $bl-linear-2;
      color: $wht;
    }
  }
  &.ky_btn_rd {
    background: $rd-linear;
    &:hover,
    &:focus {
      background: $rd-linear-2;
      color: $wht;
    }
  }
  &.ky_btn_drk {
    background: $drk-linear;
    color: $wht;
    &:hover,
    &:focus {
      background: $drk-linear-2;
      color: $wht;
    }
  }
  &.ky_btn_lght {
    background: $gry-e;
    &:hover,
    &:focus {
      background: $gry-e;
    }
  }
  &.ky_btn_blck {
    background-color: $blck;
    color: $wht;
  }
  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: $gry-7;
    color: $wht;
  }
}

.ky_btm_drwr {
  .ant-drawer-content-wrapper {
    max-width: 600px;
    width: 100% !important;
    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 1rem 1rem 0;
      .ant-drawer-content {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    @media (max-height: 550px) {
      height: 100% !important;
    }
    .ant-drawer-body {
      padding: 2rem;
      @media (max-width: 600px) {
        padding: 1rem;
      }
    }
    .ant-drawer-close {
      margin: 0.5rem 0.5rem 0 0;
      padding: 0;
      width: 20px;
      min-height: 20px;
      border-radius: 40px;
      background: $rd-4;
      svg {
        width: 10px;
        fill: $wht;
      }
      &:hover,
      &:focus {
        background: $rd-3;
      }
    }
  }
  &.ky_btm_drwr_sml {
    .ant-drawer-content-wrapper {
      max-width: 350px;
    }
  }
}

.ant-form {
  .ant-input-affix-wrapper {
    padding: 0 11px;
    box-shadow: 0 2px 4px $blck-2;
  }
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="tel"],
textarea {
  min-height: 30px;
  vertical-align: top;
  line-height: 20px;
}
input[type="tel"] {
  outline: 0;
  border: 1px solid #ddd;
  &:hover {
    border-color: #40a9ff;
  }
  &:focus {
    border-color: #40a9ff;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}
button:not(.ky_link),
input[type="submit"] {
  min-height: 30px;
  line-height: 20px;
  vertical-align: top;
}
button[role="switch"] {
  min-height: 0;
  margin: 4px 0;
  vertical-align: top;
}

.ky_title_1 {
  font-size: 3rem;
  font-weight: 500;
}
.ky_title_2 {
  font-size: 2.5rem;
  font-weight: 500;
}
.ky_title_3 {
  font-size: 2rem;
  font-weight: 500;
}
.ky_title_4 {
  font-size: 1.5rem;
  font-weight: 500;
}
.ky_title_5 {
  font-size: 1.25rem;
  font-weight: 500;
}

.kym_0 {
  margin: 0 !important;
}
.kym_1 {
  margin: 0.25rem;
}
.kym_2 {
  margin: 0.5rem;
}
.kym_3 {
  margin: 1rem;
}
.kym_4 {
  margin: 1.5rem;
}

.kymt_0 {
  margin: 0;
}
.kymt_1 {
  margin-top: 0.25rem;
}
.kymt_2 {
  margin-top: 0.5rem;
}
.kymt_3 {
  margin-top: 1rem;
}
.kymt_4 {
  margin-top: 1.5rem;
}

.kymr_0 {
  margin: 0;
}
.kymr_1 {
  margin-right: 0.25rem;
}
.kymr_2 {
  margin-right: 0.5rem;
}
.kymr_3 {
  margin-right: 1rem;
}
.kymr_4 {
  margin-right: 1.5rem;
}

.kymb_0 {
  margin: 0;
}
.kymb_1 {
  margin-bottom: 0.25rem;
}
.kymb_2 {
  margin-bottom: 0.5rem;
}
.kymb_3 {
  margin-bottom: 1rem;
}
.kymb_4 {
  margin-bottom: 1.5rem;
}

.kyml_0 {
  margin: 0;
}
.kyml_1 {
  margin-left: 0.25rem;
}
.kyml_2 {
  margin-left: 0.5rem;
}
.kyml_3 {
  margin-left: 1rem;
}
.kyml_4 {
  margin-left: 1.5rem;
}

.kyp_0 {
  padding: 0;
}
.kyp_1 {
  padding: 0.25rem;
}
.kyp_2 {
  padding: 0.5rem;
}
.kyp_3 {
  padding: 1rem;
}
.kyp_4 {
  padding: 1.5rem;
}

.kypt_0 {
  padding-top: 0;
}
.kypt_1 {
  padding-top: 0.25rem;
}
.kypt_2 {
  padding-top: 0.5rem;
}
.kypt_3 {
  padding-top: 1rem;
}
.kypt_4 {
  padding-top: 1.5rem;
}
.kypt_5 {
  padding-top: 0.75rem;
}

.kypr_0 {
  padding-right: 0;
}
.kypr_1 {
  padding-right: 0.25rem;
}
.kypr_2 {
  padding-right: 0.5rem;
}
.kypr_3 {
  padding-right: 1rem;
}
.kypr_4 {
  padding-right: 1.5rem;
}

.kypb_0 {
  padding-bottom: 0;
}
.kypb_1 {
  padding-bottom: 0.25rem;
}
.kypb_2 {
  padding-bottom: 0.5rem;
}
.kypb_3 {
  padding-bottom: 1rem;
}
.kypb_4 {
  padding-bottom: 1.5rem;
}
.kypb_5 {
  padding-bottom: 0.75rem;
}

.kypl_0 {
  padding-left: 0;
}
.kypl_1 {
  padding-left: 0.25rem;
}
.kypl_2 {
  padding-left: 0.5rem;
}
.kypl_3 {
  padding-left: 1rem;
}
.kypl_4 {
  padding-left: 1.5rem;
}

.ky_cntr {
  text-align: center;
}
.ky_lft {
  text-align: left;
}
.ky_rght {
  text-align: right;
}

.ky_brdr_btm {
  border-bottom: 1px solid $blck-06;
}
.ky_brdr_tp {
  border-top: 1px solid $blck-06;
}

.ky_loading {
  background-color: $pnk-1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
}

.ky_status_img {
  display: block;
  max-width: 100px;
  margin: 2rem auto;
}

.ky-err-txt {
  color: $rd-3;
  margin: 0.5rem 0;
}

.ky-success-txt {
  color: $grn-6;
  margin: 0.5rem 0;
}

.ky_modal {
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  max-width: 365px;
  width: calc(100% - 1rem) !important;
  &.ky_modal_lg {
    max-width: 100%;
  }
  .ant-modal-content {
    .ant-modal-close {
      width: 20px;
      height: 20px;
      min-height: 20px;
      display: inline-block;
      margin: 5px;
      border-radius: 20px;
      background: $rd-4;
      .ant-modal-close-x {
        width: 20px;
        height: 20px;
        line-height: 20px;
        svg {
          fill: $wht;
          width: 12px;
        }
      }
      &:hover,
      &:focus {
        background: $rd-3;
      }
    }
    .ant-modal-header {
      padding: 2px 1.5rem;
      min-height: 30px;
      vertical-align: middle;
      background: $gry-linear-2;
      .ant-modal-title {
        font-size: 14px;
      }
      div {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .ant-modal-body {
      .modal_content {
        .modal_icon {
          width: 50px;
          height: 50px;
          display: inline-block;
          margin-right: 1rem;
          margin-bottom: 1.5rem;
          padding: 8px;
          border-radius: 50px;
          border: 2px solid $yllw-1;
          svg {
            width: 30px;
            height: 30px;
            fill: $yllw-1;
          }
        }
        p {
          width: calc(100% - 50px - 1rem);
          display: inline-block;
          line-height: 22px;
          margin-bottom: 1.5rem;
        }
      }
      @media (max-width: 600px) {
        padding: 1rem;
      }
    }
  }
}

.ky-w-100 {
  width: 100%;
}

.ky-msg-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0 0.5rem;
  > p {
    width: calc(100% - 58px);
  }
}

.animation-ctn {
  text-align: center;
  margin-top: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
}
.ky-svg-wrapper {
  @include transform(scale(0.3) translate(-115%, -115%));
  display: inline-block;
}
@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 200px;
  }
}
@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 200px;
  }
}
@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 0px;
  }
}
@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }

  100% {
    stroke-dashoffset: 960px;
  }
}
@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}
@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }

  100% {
    stroke-dashoffset: 960px;
  }
}
@keyframes colored-circle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}
.inlinesvg .svg svg {
  display: inline;
}
.ky-svg-wrapper svg polyline,
.ky-svg-wrapper svg polygon {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}
.ky-svg-wrapper svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}
.ky-svg-wrapper svg circle#colored {
  -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}

.ky_icon {
  width: 20px;
  height: 20px;
}

.ky_modal_nv {
  .ant-modal-content {
    background: $gry-fe;
  }
  .ant-modal-header {
    background: $gry-fe;
    border-bottom: unset;
    border-radius: 5px;
  }
  .ant-modal-title {
    font-size: 22px;
    text-align: center;
  }
  label {
    color: $gry-46
  }
}
