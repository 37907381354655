@import "./addOrder//addOrder.scss";
@import "./searchOrders/searchOrders.scss";

.ky_table_tag {
  padding: 0.5%;
  margin: 1%;
}

.ky_btn {
  background-color: $trnsp;
  border-radius: 6px;
  border: none !important;
  outline: 0;
  line-height: 20px;
  box-shadow: 0 2px 4px $blck-2;
  height: 30px;
  color: $wht;
  &.ky_btn_rng {
    background: $rng-linear;
    &:hover,
    &:focus {
      color: $wht;
      background: $rng-linear-2 !important;
    }
  }
}

.ky_sht_rw {
  border-bottom: 1px solid $blck-1;
}

.ky_sht_btns {
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 24px;
}

.ky_btn_action {
  width: 120px;
  border-radius: 6px;
}

.ky_crd_tbl_ordrs {
  cursor: default;
  background-color: $trnsp;
  .ant-card-head {
    .ant-card-head-title {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 1rem;
    .ant-card-body {
      padding: 0;
    }
    .ant-card-head {
      padding: 0;
    }
  }
}

.ky_drwr_clmn {
  font-weight: 500;
}

.ky_ldmr {
  justify-content: center;
  display: flex;
}

.ky_btn_ldmr {
  margin-top: 0.5rem;
  padding: 1 2rem;
  background-color: $gry-a9;
  &:hover,
  &:focus {
    color: $wht;
    background: $gry-co !important;
  }
}

.ant-drawer-body {
  .ky_btn_rd,
  .ky_btn_bl,
  .ky_btn_grn {
    &.ky_btn.ky_ntf_btn {
      display: block;
      border-radius: 100%;
      padding: 0;
      height: 40px;
      width: 40px;
    }
  }
  .ky_ntf_spc {
    @media (max-width: 600px) {
      width: 100%;
      justify-content: center;
    }
    div {
      text-align: center;
    }
  }
}

.ant-modal-body {
  .ky_btn_rd,
  .ky_btn_bl,
  .ky_btn_grn {
    &.ky_btn.ky_ntf_btn {
      width: 100px;
      @media (max-width: 350px) {
        width: 100%;
      }
    }
  }
  .ky_ntf_spc {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    padding-bottom: 1rem;
  }
}

.ky_tb_nv {
  // background-color: $gry-f1;
  border-radius: 2px;
  overflow: visible;
  .ant-tabs-content-holder {
    overflow-x: auto;
  }
  .ant-tabs-nav {
    margin: 0 !important;
    .ant-tabs-nav-wrap {
      margin-top: -2.25rem;
    }
    .ant-tabs-nav-list {
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 0 2px 4px $blck-2;
      margin-bottom: 1.5rem;
      @media (max-width: 767px) {
        flex-direction: column;
        box-shadow: none;
        width: 100%;
      }
      .ant-tabs-tab {
        border: none;
        border-left: 1px solid $gry-d1;
        padding: 2px 1rem;
        min-height: 30px;
        @include transition(0s);
        * {
          @include transition(0s);
        }
        &:last-child {
          border-left: none;
        }
        &:hover,
        &:focus,
        &:hover:focus {
          color: $wht;
          background: $drk-linear-2;
        }
        .ant-tabs-tab-btn {
          &:active {
            color: $wht;
          }
        }
      }
      .ant-tabs-ink-bar-animated {
        display: none;
      }
    }
  }
  .ky_order_tbl {
    min-height: 400px;
    overflow-y: auto;
    cursor: default;
    .ky_rw_act {
      td {
        background-color: $rng-9;
      }
    }
    .ant-table-header{
        border-bottom: 1px solid $gry-9 !important;
        .ant-table-column-sorters {
          padding: 16px 0px !important;
        }
    }
    .ant-table-container {
      border: none;
      table {
        thead {
          tr {
            background-color: $gry-fe !important;
            th {
              background-color: $trnsp;
              padding: 8px 1rem;
              text-align: left;
              border-right: none !important;
            }
          }
        }
        tr {
            cursor: pointer;
            border-bottom: 1px solid #dfdfdf !important;
          td {
            padding: 6px 1rem;
            height: 45px;
            border-right: none !important;
            font-weight: 400;
            text-align: left;
          }
        }
      }
    }
  }
}

.ky_srch_tbbdy {
  margin-top: 15px;
}

.order-details-tags {
  cursor: pointer;
  .anticon.anticon-user > svg {
    color: white;
  }
  .ant-tag {
    margin-top: 4px;
  }
}

.ky_column, .ky_order_tbl .ant-table-cell{
    min-width: 180px;
    @media(max-width:1440px){
      min-width: 170px;
  }
    @media(max-width:1416px){
        min-width: 160px;
    }
    @media(max-width:1352px){
        min-width: 150px;
    }
    @media(max-width:1292px){
        min-width: 140px;
    }
    @media(max-width:1235px){
      min-width: 130px;
  }
    @media(max-width:1176px){
        min-width: 120px;
    }
    @media(max-width:1118px){
        min-width: 100px;
    }
    @media(max-width:979px){
        min-width: 80px;
    }
    @media(max-width:855px){
        min-width: 60px;
    }
    @media(max-width:742px){
        min-width: 50px;
    }
    @media(max-width:678px){
        min-width: 40px;
    }

    .bpr_no{
      color: $gry-8;
    }

    .ky_status_col{
      align-items: flex-start;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;


      .ky_bz_ct{
        padding: 5px;
        font-size: 12px;
        font-style: italic;

        @media(max-width:953px){
          padding: 5px 0 !important;
          font-size: 11px !important;
        }
      }
    }

    .ky_status_clm{
        border-radius: 20px;
        padding: 2px;
        color: $gry-46;
        text-align: center;
        padding: 0 15px;
        @media(max-width:953px){
          padding: 0 10px !important;
          font-size: 12px !important;
        }

        &.in_progress {
            border: 1px solid #ffd6b3;
            background-color: #ffe4cc;
        }

        &.waiting_for_pick_up {
            border: 1px solid #b3e4ff;
            background-color: #ccedff;
        }

        &.completed {
            border: 1px solid #86df9b;
            background-color: #99e4aa;
        }
    }
}


.ky_svc_dt_pck {
  width: 220px;
  border: 0;
  border-bottom: 1px solid $gry-9c;
  cursor: pointer;
  &.ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-input > input{
      font-weight: 600 !important;
  }
  @media(max-width:635px){
    width: 100% !important;
    margin-top: 4px;
    margin-bottom: 5px;
 }
}

.ky_svc_slt {
  min-width: 200px;
  max-width: calc(100% - 370px);
  .ant-select-selector {
    border: 0px !important;
    border-bottom: 1px solid $gry-9c !important;
    box-shadow: none !important;
  }
  .ant-select-selection-item {
      font-weight: 600;
  }
  @media(max-width:635px){
    width: calc(100% - 50px) !important;
    margin-top: 4px;
    max-width: 100% !important;
 }
}

.ky_rld_btn {
  margin-left: 3px !important;
  @media(max-width:635px){
    height: 35px;
 }
}

.ky_swtch_btn {
  margin-left: 8px !important;
}

.ky_svc_tip {
  margin: 15px 0;
  font-size: 14px !important;
  font-style: italic;
  font-weight: 500;
  color: $gry-5;
  word-break: break-word;
  white-space: pre-line;
  b {
    font-size: 14px !important;
    word-break: break-word;
    white-space: pre-line;
  }
}

.ky_svc_title{
    padding-right: 10px;
    h4 {
        margin: 3px 0px !important;
        font-size: 16px;
    }
    @media(max-width:610px){
        width: 100% !important;
    }
}

.ky_odr_header_col {
 // border: 1px solid;
  display: inline;
  float: left;
  &.left {
    width: 70%;
    @media(max-width:968px){
      width: 100%;
    }
  }
  &.right {
    width: 30%;
    position: relative;
    @media(max-width:968px){
      width: 100%;
    }
  }
}

.ky_odr_srch_wrp{
  margin-top: 15px;
}

.kyu_phn_mdl_fld {
  width: 100%;
  text-align: center;
  font-size: 24px;
}

.ky_svc_dt_rng_pck {
  width: 420px;
  border: 0;
  border-bottom: 1px solid $gry-9c;
  cursor: pointer;
  &.ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-input > input{
      font-weight: 600 !important;
  }
  @media(max-width:635px){
    width: 100% !important;
    margin-top: 4px;
    margin-bottom: 5px;
 }
}