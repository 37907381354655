.ky_srch_wrp{
    width: 100%;
    .ky_srch{
        &.ant-input-search-with-button{
            display: block;
            margin: 10px 0;
            max-width: 600px;
            width: 100%;
            @media(max-width:968px){
                max-width: calc(100% - 160px) !important;
            }
            @media(max-width:767px){
                max-width: calc(100% - 140px) !important;
            }
            @media(max-width:572px){
                max-width: 100% !important;
            }
        }
        .ant-input-affix-wrapper{
            height: 43px;
            padding: 0 1rem;
            input{
                min-height: 28px;
            }
        }
        .ant-input-group-addon{
            button{
                height: 43px;
                padding: 14px;
                outline: 0;
                border: none;
                &:hover, &:focus{
                    background: $rng-linear;
                }
            }
        }
    }
}