.ky_lgn_vw {
  background-color: $pnk-1;
}

.ky_frm {
  &.ky_frm_lgn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @media (max-width: 767px) {
      max-width: calc(100% - 2rem);
      margin: auto;
      > div {
        width: 100%;
      }
    }
  }
}

.ky_logo {
  &.ky_logo_lgn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
}

.ky_crd {
  &.ky_crd_lgn {
    background-color: $gry-f7 !important;
    border-radius: 8px;
    width: 400px;
    height: 300;
    box-shadow: 0 5px 10px $blck-2;
    margin: auto;
    
    &.ant-card {
      .ant-card-body{
        padding: 32px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 400px;
    }
  }
}

.ky_lbl_lgnfrm {
  font-size: 16px !important;
  font-family: Lato;
}

.ky_int {
  &.ky_int_lgnfrm {
    box-shadow: 0 2px 4px $blck-2;
    padding: 0;
    font-size: initial !important;
    font-family: "Lato";
    input{
      padding: 0 1rem;
    }
    .ant-input-suffix{
      padding: 0 0.5rem;
      margin: 0;
    }
  }
}
input{
  &.ky_int{
    &.ky_int_lgnfrm{
      padding: 0 1rem;
    }
  }
}

.ky_errmsg {
  color: $rd-3;
  background-color: $pnk-2;
  border-color: $pnk-3;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 6px;
}

.ky_cd_msg {
  color: $grn-6;
  background-color: $grn-7;
  border-color: $grn-6;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 6px;
}

.ky_btn_lgn {
  border-radius: 6px;
  font-size: initial;
  font-family: Lato;
  background: $rng-linear !important;
}

.ky_fgtpwd{
  justify-content: center;
  display: grid;
}

.ky_fgtpwd_btn_spn {
  text-decoration: underline;
  font-style: italic;
  color: $gry-5;
  font-size: initial;
  font-family:Lato;
}

.ky_fgtpwd_ttle{
  display: block;
  text-align: center;
  font-size: 1.25rem;
  margin: 1rem 0;
  font-weight: 500;
}

.ky_pwd_nt{
  color:$gry-6;
  margin-top: 0.5rem;
}

.ky_fgtpwd_txt{
  display: block;
  text-align: left;
}

.alert{
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}


.ky_spin {
  padding-right: 5px;
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
